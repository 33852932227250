@import '../../../assets/styles/variables.scss';

.summary-card-details {
  .loader {
    width: 100%;
    height: 100%;
    justify-content: center;
  }

  .error-wrapper {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .ant-drawer-body {
    padding: 16px 24px;
  }

  .ant-divider {
    border-block-start: 1px solid $black-10;
  }

  &-description {
    font-size: 12px;
    margin-bottom: 16px;
    margin-top: 18px;
    line-height: 20px;
  }

  .default-attributes {
    gap: 16px;

    &-info {
      width: calc(50% - 8px);
    }
  }

  &-info-message {
    margin-bottom: 16px;
    align-items: flex-start;
    gap: 10px;
  }

  .ant-tag {
    display: inline-flex;
    max-width: 100%;
    text-transform: none;
    margin-bottom: 3px;

    .name {
      padding-right: 5px;
    }

    .index {
      padding: 0 5px 0 2px;
      font-family: 'WorkSans-SemiBold';

      &.green {
        color: $green-la-palma;
      }

      &.red {
        color: $red-crimson;
      }

      .separator {
        color: $black-88;
        font-family: 'WorkSans';
      }
    }
  }
}