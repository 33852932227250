@import '../../../../assets/styles/variables.scss';

.attribute-content {
  padding-bottom: 20px;
  border-bottom: 1px solid $black-10;

  &:last-child {
    border-bottom: 0;
  }

  &.grid-view {
    &:nth-child(2n + 1) {
      border-right: 1px solid $black-10;
    }

    &.even {
      &:nth-last-child(2) {
        border-bottom: 0;
      }
    }
  }

  .favourite-button {
    color: $black-88;

    &.checked {
      color: $blue-navy;
    }
  }

  .no-attribute {
    font-size: 12px;
    color: $black-88;
    margin-bottom: 10px;
  }

  .diagram-switcher {
    align-items: flex-end;
    display: flex;
    justify-content: flex-end;
    position: relative;
    top: -6px;

    &.group {
      padding-bottom: 7px;
      top: 0;
    }
  }

  &.has-more {
    .attribute-content-wrapper {
      height: calc(325px + var(--headers-height));
      overflow: hidden;
      transition: height 0.6s;
      position: relative;
    }

    &.expanded .attribute-content-wrapper {
      height: calc(var(--values-height) + 20px);

      .show-more {
        background: none;
        height: 40px;
      }
    }
  }

  .empty {
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 13px;
    padding: 65px 0;

    .text {
      color: $black-88;
      font-size: 12px;
      max-width: 100%;
      text-align: center;
    }

    .anticon {
      font-size: 184px;
    }
  }

  .show-more {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 75px;
    background: linear-gradient(0deg, $white 21%, $white 0%, transparent 69.3%);
    justify-content: center;
    display: flex;
    align-items: flex-end;
  }
}

.tooltip-element {
  background: $white;
  border-radius: 3px;
  color: $black;
  opacity: 1;
  pointer-events: none;
  position: absolute;
  transition: all .1s ease;
  box-shadow: 0px 2px 6px 0px $black-25;
  white-space: nowrap;
}

.legend-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}