@import '../../assets/styles/mixins.scss';
@import '../../assets/styles/variables.scss';

.login {
	@include flex-column-center;
	position: relative;
	background: $white-wild-sand;
	min-height: calc(100vh);

	&__main {
		@include flex-column-center;
		background-color: $white;
		width: 420px;
		height: 100%;
		padding: 3rem;
		margin-top: 10vh;
		margin-bottom: 100px;
		border: 1px solid $black-10;
		border-radius: 0.5rem;
		gap: 24px;

		.logo_box {
			@include flex-column-center;
			width: 100%;
			padding: 16px 0;
			border: 1px solid $black-10;
			border-radius: 0.5rem;
			gap: 8px;

			&__text {
				font-family: WorkSans-Bold, serif;
				letter-spacing: 1px;
				text-transform: uppercase;
			}
		}

		.title {
			@include flex-column;
			width: 100%;
			gap: 6px;
			text-align: center;
		}
	}

	&__footer {
		@include flex-column-center;
		position: absolute;
		bottom: 3vh;
	}
}

.sso {
	&-container {
		width: 100%;

		.ant-btn {
			width: 100%;
			margin: 0;
		}
	}

	&-or {
		margin: 20px 0 0;
		display: block;
		text-align: center;
		position: relative;

		span {
			display: inline-block;
			padding: 10px;
			background: $white;
			position: relative;
			z-index: 2;
		}

		&:after {
			content: '';
			position: absolute;
			height: 1px;
			background: $black-10;
			width: 100%;
			left: 0;
			top: 50%;
		}
	}
}

@media screen and (max-height: 700px) {
	.login {
		&__main {
			margin-top: 8vh;
		}
	}
}