@import '../../../../assets/styles/variables.scss';

.competitors-collapse {
  .title {
    padding: 0 0 16px;
  }

  .manufacturer-title {
    padding-top: 16px;
  }

  .devider {
    margin: 16px 0;
  }

  .all-btn {
    margin-bottom: 16px;
    width: 100%;
  }
}