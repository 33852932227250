.collapse-tag {
	max-width: 610px;

	&__wrapper {
		margin-top: 1rem;
	}

	&.is-visible {
		height: auto;
	}

	&.is-hidden {
		margin-top: 0;
		visibility: hidden;
		animation: collapseHeight 0.3s forwards;
	}

	.source {
		color: rgba(0, 0, 0, 0.45);
		margin-right: 4px;
	}
}

@keyframes expandHeight {
	from {
		height: 0;
		margin-top: 0;
	}

	to {
		height: auto;
	}
}

@keyframes collapseHeight {
	from {
		height: auto;
		margin-top: 1rem;
		display: inherit;
	}

	to {
		display: none;
	}
}