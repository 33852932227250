@import '../../../assets/styles/variables.scss';

.category-title h4 {
  padding: 16px 12px 0;
}

.attribute-title {
  h5 {
    padding: 16px 0 12px;
  }

  @media all and (max-width: 1440px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.attributes-comparison-spinner {
  padding: 24px;
  justify-content: center;
  min-height: 100px;

  .ant-spin {
    margin-bottom: 10px;
  }
}

.select-comparison-to-show .ant-select-selection-item {
  &:before {
    content: 'Comparison Audiences: '
  }
}

.diagram-content {
  &-wrapper {
    padding-bottom: 32px;
    height: 100%;
  }
}

.select-attribute-to-show .ant-select-selection-item {
  &:before {
    content: 'Show: '
  }
}

.attributes-comparison {
  &-content {
    padding: 0 24px 24px;

    .ant-tabs-nav {
      .ant-tabs-tab {
        border-color: $black-10;

        &.ant-tabs-tab-active {
          font-family: 'WorkSans-SemiBold';
        }
      }
    }

    .attribute-content-options {
      @media all and (max-width: 1440px) {
        flex: 0 0 50%;
        max-width: 50%;
      }

      >div {
        height: 100%;
        padding-bottom: 7px;
        padding-top: 13px;
      }

      @media screen and (max-width: 1200px) {}
    }

    .ant-tabs-content-holder {
      min-height: 500px;
    }

    .attribute-content-inner {
      padding: 10px 12px 0;

      .select-attribute-to-show {
        width: auto;
        max-width: calc(100% - 210px);
      }

      .attribute-to-show-grid {
        display: flex;
        justify-content: flex-end;
        margin: 5px 0;
      }

      >.ant-row.grid-group {
        >.ant-col-16>div {
          flex-wrap: wrap;

          .select-attribute-to-show {
            max-width: 100%;
            order: 3;
          }
        }

        @media screen and (max-width: 1200px) {

          >.ant-col-8,
          >.ant-col-16 {
            max-width: 100%;
            flex: 0 0 100%;
          }

          >.ant-col-16 {
            >div {
              padding: 0 0 7px;
              justify-content: space-between;

              .favourite-button {
                margin-left: auto;
              }
            }
          }
        }
      }
    }
  }
}

.view-switcher {
  margin-right: 16px;
}

.download-button {
  color: $black-88;
}

.show-switch {
  padding-right: 16px;
  color: $black-65;
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;

  .index-description {
    .anticon svg {
      font-size: 16px;
    }

    .ant-popover-arrow {
      right: 8px;
    }
  }
}

.bar-chart-legend {
  font-size: 12px;
  line-height: 15px;
  color: $gray;
  padding: 4px 0;
  display: flex;
  gap: 8px 16px;
  white-space: nowrap;
  flex-wrap: wrap;
  margin-bottom: 8px;
  max-width: 100%;

  &.doughnut {
    max-width: calc(100% - 135px);
  }

  .legend-color {
    max-width: 100%;
    position: relative;
    padding-left: 18px;
    display: flex;

    .text-popover-width {
      display: inline-block;
      vertical-align: middle;
    }

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 4px;
      display: inline-block;
      width: 8px;
      height: 8px;
      background-color: var(--legend-color);
    }
  }

  .ant-badge {
    margin-left: 5px;
    vertical-align: middle;
  }
}