@import '../../../../../assets/styles/variables.scss';

.buyer-types {
  padding-top: 16px;

  .info-circle {
    color: $black-45;
  }

  &-buttons {
    width: 100%;
    padding-top: 16px;

    >.ant-btn {
      width: 100%;
    }
  }

  .blade-buttons {
    width: 100%;
    padding-top: 16px;
    width: '-webkit-fill-available';
  }
}

.info-circle {
  padding-left: 8px;
  cursor: pointer;
}