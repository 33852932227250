@import '../../../assets/styles/variables.scss';

.detailed-attributes {
  margin-top: 40px;

  .empty-note {
    color: $black-45;
    font-size: 12px;
  }

  &-item {
    margin-bottom: 18px;
  }

  &-values {
    margin-bottom: 10px;
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    .ant-tag {
      margin-bottom: 0;
      margin-inline-end: 0;
    }

    .ant-btn {
      padding: 0;
      height: auto;
    }
  }
}