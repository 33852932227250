@import '../../assets/styles';

.audience-comparison {
	display: flex;
	justify-content: center;

	.error-image {
		font-size: 255px;
		width: 1em;
		height: 1em;
	}

	&.error {
		align-items: center;
		gap: 24px;
		height: calc(100% - 55px);

		h3 {
			margin-top: 0;
		}

		.caption {
			color: $black-45;
		}
	}

	&.loading {
		height: calc(100% - 55px);
	}

	&-spinner {
		.ant-spin {
			margin-bottom: 10px;
		}
	}

	&-content {
		border-bottom: 1px solid $black-10;
		height: 530px;
		width: 100%;
	}

	.section-title {
		padding: 22px 24px 10px;
		font-family: 'WorkSans-SemiBold';
		width: 100%;
	}

	.scenario-overview-description {
		padding: 0 24px 24px;
	}

	&-header {
		background-color: #F5F5F5;
		padding: 24px;

		.caption {
			font-size: 12px;
			margin-top: 2px;
			line-height: 20px;
			max-width: 100%;
			display: flex;
			flex-wrap: wrap;
			column-gap: 8px;

			&-loader {
				position: fixed;
				inset: 55px 0 0 0;
				background-color: $white;
				z-index: 2;
				align-items: center;

				.ant-spin {
					margin-bottom: 10px;
				}
			}

			>div {
				max-width: 100%;
				display: flex;
				align-items: center;
				gap: 8px;
				margin-bottom: 5px;
			}
		}

		.vs {
			padding: 0 5px;
			border: 1px solid $black-15;
			border-radius: 4px;
			background-color: $black-6;
			line-height: 18px;
		}

		&-buttons {
			padding-top: 8px;
		}
	}
}