@import '../../../../../assets/styles/variables.scss';

.specify-time-filter {
  .ant-picker-panels>*:last-child button.ant-picker-header-prev-btn {
    visibility: visible !important;
  }

  .ant-picker-panels>*:last-child button.ant-picker-header-super-prev-btn {
    visibility: visible !important;
  }

  .ant-picker-panels>*:first-child {
    display: none;
  }

  .ant-picker-panel-container,
  .ant-picker-footer {
    width: 280px !important;
  }

  .ant-picker-footer-extra>div {
    flex-wrap: wrap !important;
  }

  .ant-btn {
    width: 100%;

    &:hover:not(.ant-btn-disabled) {
      background: none;
    }
  }

  .ant-collapse-expand-icon {
    position: absolute;
    right: 24px;

    .anticon svg {
      color: $black-45;
      font-size: 16px;
    }
  }

  .ant-picker-footer .ant-picker-footer-extra {
    text-align: center;
  }
}