@import '../../../assets/styles/variables.scss';

.dasboard-card {
    .ant-card-body {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        position: relative;
        height: 220px;

        &::before,
        &::after {
            display: none;
        }

        .ant-dropdown-menu-title-content,
        .ant-dropdown-menu-item {
            padding: 0;

            .popover-button {
                padding: 10px 12px;

                &.delete {
                    color: $red-crimson;
                }
            }
        }
    }

    .tooltip-tags {
        position: absolute;
        top: 46px;
        left: 15px;
        width: calc(100% - 30px);
        border-radius: 4px;
        background: $white;
        box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.25);
        padding: 12px;
        margin-top: 10px;
        opacity: 0;
        transition: all 0.3s;
        pointer-events: none;
        flex-wrap: wrap;
        display: flex;
        z-index: 3;

        .error {
            color: $red-crimson;
        }

        .loader {
            text-align: center;
            display: block;
            width: 100%;
        }

        .collapse-tag {
            span {
                white-space: nowrap;
            }
        }
    }

    .tags {
        &:hover {
            cursor: pointer;

            .tooltip-tags {
                opacity: 1;
                pointer-events: all;
            }
        }
    }

    &-content {
        h5 {
            margin-top: 0;
        }
    }

    &-header {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: flex-end;
    }

    .main-icon {
        font-size: 32px;
        color: $blue-navy;
    }

    .template-actions {
        display: flex;
        gap: 8px;

        .anticon {
            font-size: 24px;
        }
    }
}

.dashboard_card-modal {
    .ant-form {
        margin-bottom: 40px;
    }

    .ant-input-suffix {
        position: absolute;
        top: calc(100% + 5px);
        right: 0;
        pointer-events: none;
    }
}