@import '../../../../assets/styles/mixins.scss';
@import '../../../../assets/styles/variables.scss';

.buyer-type-tooltip__d2c {
	@include flex-column;
	font-size: 12px;

	.buyer-type-name__tooltip {
		font-family: 'WorkSans-SemiBold';
	}

	.buyer-type-description__tooltip {
		padding-left: 3px;
	}
}

.additional-parameters {
	.type-radio-group {
		.ant-radio-group {
			width: 100%;
		}
	}
}

.search-affinity {
	padding-top: 16px;
}

.search {
	.show-clear-icon {
		.ant-input-clear-icon-hidden {
			visibility: visible;
		}
	}

	.anticon-search {
		color: $black-88;
	}

	&-matches {
		font-size: 12px;
		color: $black-45;
		line-height: 20px;
		display: inline-block;
		margin-top: 5px;
	}
}

.affinity-list {
	&-name {
		font-size: 16px;
		margin: 16px 0;
		font-family: 'WorkSans-SemiBold';
	}

	.all-button {
		width: 100%;
		margin-bottom: 16px;
	}
}

.engagement {
	.ant-divider-horizontal {
		margin: 16px 0;
	}

	.title {
		padding-bottom: 16px;
	}

	.ant-radio-group {
		width: 100%;
	}
}