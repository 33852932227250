@import '../../assets/styles/variables.scss';

.audience-comparison-empty {
	padding-top: 56px;
	display: flex;
	flex-direction: column;
	align-items: center;

	.title {
		font-family: 'WorkSans-SemiBold';
		font-size: 38px;
		line-height: 46px;
	}

	.caption {
		padding: 10px 0 20px;
	}

	.default-venn {
		position: relative;
		width: fit-content;

		.parameters {
			position: absolute;
			display: flex;
			flex-direction: column;
			align-items: center;
			width: 338px;
			top: 30%;

			&.comparison {
				transform: translate(85%, 0);
			}

			.text {
				margin: 0;
				font-weight: 400;
				font-size: 14px;
				line-height: 22px;
			}

			.select-parameters-link {
				margin-top: 10px;
				color: #0000a0;
				text-decoration: underline;
				cursor: pointer;
			}
		}

		&-circle {
			&-item {
				fill: #f5f5f5;
				stroke: #c3c3c3;
				stroke-width: 2px;
				stroke-dasharray: 3;
				opacity: 0.75;
			}
		}
	}
}

.templates {
	padding: 24px;

	.title {
		padding: 24px 0;
	}

	.empty {
		width: 100%;
		align-items: center;
		justify-content: flex-start;
		flex-direction: column;
		gap: 13px;
		padding: 24px 0;

		.text {
			color: $black-45;
			max-width: 100%;
			text-align: center;
		}

		.anticon {
			font-size: 184px;
		}
	}
}