$blue-cerulean: rgb(0, 158, 235);
$blue-navy: rgb(0, 0, 160);
$blue-navy-85: rgba(0, 0, 160, .85);
$blue-zircon: rgb(240, 245, 255);

$green-mountain-meadow: rgb(34, 178, 121);
$green-mountain-meadow-85: rgba(34, 178, 121, 0.85);
$green-la-palma: rgb(56, 158, 13);
$green-japanese-laurel: rgb(35, 120, 4);

$purple-heart: rgb(122, 54, 190);
$purple-heart-85: rgba(122, 54, 190, 0.85);

$black: rgb(0, 0, 0);
$black-2: rgba(0, 0, 0, 0.02);
$black-4: rgba(0, 0, 0, 0.04);
$black-6: rgba(0, 0, 0, 0.06);
$black-10: rgba(0, 0, 0, 0.10);
$black-15: rgba(0, 0, 0, 0.15);
$black-16: rgba(0, 0, 0, 0.16);
$black-25: rgba(0, 0, 0, 0.25);
$black-45: rgba(0, 0, 0, 0.45);
$black-65: rgba(0, 0, 0, 0.65);
$black-88: rgba(0, 0, 0, 0.88);

$gray: rgb(140, 140, 140);

$white: rgb(255, 255, 255);

$white-wild-sand: rgb(245, 245, 245);

$red-crimson: rgb(207, 19, 34);
$red-shiraz: rgb(168, 7, 26);

$orange-sunset: rgb(255, 77, 79);