@import '../../assets/styles/';

.text-popover {
    max-width: 100%;

    &-width {
        @include truncate(100%);
        display: block;
    }

    &-height {
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}