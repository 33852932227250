.modal-template-content {
    margin-bottom: 10px;
}

.save-template {
    .ant-form {
        margin-bottom: 40px;
    }

    .ant-input-suffix {
        position: absolute;
        top: calc(100% + 5px);
        right: 0;
        pointer-events: none;
    }
}