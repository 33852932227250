@import './assets/styles/variables.scss';

@font-face {
  font-family: 'WorkSans';
  src:
    local('WorkSans'),
    url(./assets/fonts/WorkSans-Regular.woff) format('woff');
}

@font-face {
  font-family: 'WorkSans-SemiBold';
  src:
    local(WorkSans-SemiBold),
    url(./assets/fonts/WorkSans-SemiBold.woff) format('woff');
}

@font-face {
  font-family: 'WorkSans-Bold';
  src:
    local(WorkSans-Bold),
    url(./assets/fonts/WorkSans-Bold.woff) format('woff');
}

@font-face {
  font-family: 'WorkSans-Italic';
  src:
    local(WorkSans-Bold),
    url(./assets/fonts/WorkSans-Italic.woff) format('woff');
}

body {
  font-size: 16px;
  margin: 0 auto;
  height: 100%;
  max-width: var(--max-width);
  cursor: default;
}

html,
#root {
  height: 100%;
}

p {
  margin: 0;
}

b,
strong {
  font-family: 'WorkSans-SemiBold';
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 6vh;
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  .icon-back {
    position: absolute;
    justify-self: flex-start;
    left: 20px;
  }

  .icon-help {
    position: absolute;
    right: 20px;
    font-size: 18px;
    cursor: pointer;
    color: $black-88;
    white-space: nowrap;
  }

  .icon-cds {
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      g {
        mix-blend-mode: darken;
      }
    }

    .cds {
      font-weight: 700;
      margin-left: 8px;
    }
  }
}

.note-caption {
  padding-top: 16px;

  span {
    font-family: 'WorkSans-Italic';
    color: $black-45;
  }
}

.ant-modal {
  .ant-modal-content {
    .ant-modal-title {
      font-family: 'WorkSans-SemiBold';
      font-weight: normal;
    }
  }
}