.wizzard-tabs {
	.ant-tabs-nav {
		margin-bottom: 24px;

		.ant-tabs-nav-operations {
			display: none;
		}
	}

	&.short {
		.ant-tabs-tab+.ant-tabs-tab {
			margin-left: 10px;
		}
	}

	.ant-tabs-tab-btn {
		.ant-tabs-tab-icon:not(:last-child) {
			margin-inline-end: 8px;
		}
	}

	.ant-tabs-extra-content {
		position: absolute;
		z-index: 2;

		.ant-btn {
			position: relative;
			z-index: 2;
		}

		&:after {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			width: 200px;
			height: 100%;
			background: linear-gradient(90deg, #fff 0%, rgba(255, 255, 255, 0.00) 69.3%);
			display: flex;
			align-items: center;
			z-index: 1;
			pointer-events: none;
		}

		&:last-child {
			right: 0;
			justify-content: flex-end;

			&:after {
				left: unset;
				right: 0;
				background: linear-gradient(-90deg, #fff 0%, rgba(255, 255, 255, 0.00) 69.3%);
			}
		}
	}

	.ant-tabs-nav-wrap {

		&:before,
		&:after {
			display: none;
		}

		.ant-tabs-tab-disabled {
			cursor: pointer;
			padding: 0;

			.ant-dropdown-trigger {
				padding: 12px 0;
			}
		}
	}
}

.custom-styled-dropdown {
	.ant-dropdown-menu {

		.ant-dropdown-menu-submenu-title,
		.ant-dropdown-menu-item {
			padding: 9px 24px;

			.anticon {
				margin-right: 8px;
				font-size: 16px;
			}
		}
	}
}