@import '../../../../assets/styles/variables.scss';

.attributes-comparison {

  &-drawer {
    .ant-collapse {
      &-arrow svg {
        font-size: 16px;
        color: $black-45;
      }
    }

    .ant-drawer-body {
      padding: 0;

      .search {
        border-bottom: 1px solid $black-10;
        padding: 12px 24px;

        .show-clear-icon {
          .ant-input-clear-icon-hidden {
            visibility: visible;
          }
        }

        .anticon-search {
          color: $black-88;
        }

        &-matches {
          font-size: 12px;
          color: $black-45;
          line-height: 20px;
          display: inline-block;
          margin-top: 5px;
        }
      }

      .ant-collapse {
        &-header {
          padding: 14px 24px;
          font-size: 14px;
          font-family: 'WorkSans-SemiBold';
        }

        &-item {
          border-bottom: 1px solid $black-10;
        }

        &-content {
          .ant-collapse-content-box {
            padding: 24px 24px 12px;
            padding-block-start: 0;
          }

          .ant-tree-indent-unit {
            width: 12px;
          }
        }
      }

      .ant-tree {
        &-treenode {
          position: relative;
          width: 100%;
          padding: 6px 12px;
          margin-bottom: 4px;

          &-motion {
            width: 100%;
          }

          .ant-tree-checkbox {
            margin-inline-end: 4px;
          }

          &.parent {
            background-color: $blue-zircon;
            border-radius: 4px;
            width: 100%;
            padding: 9px 12px;
            position: relative;
            font-family: 'WorkSans-SemiBold';
            margin-bottom: 4px;
          }

          &.highlight {
            background-color: $black-4;
          }
        }

        &-node {
          &-content-wrapper {
            &:hover {
              background-color: transparent;
            }
          }
        }
      }

      .ant-tree-switcher {
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translateY(-50%);
        width: 16px;
        height: 16px;

        svg {
          font-size: 16px;
          color: $black-45;
        }

        &_close {
          svg {
            transform: rotate(-180deg);
          }
        }
      }
    }

    .ant-drawer-extra {
      display: flex;
      gap: 8px;
    }
  }
}