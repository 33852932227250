@import '../../../assets/styles/variables.scss';

.scenario-group {
  margin-top: 12px;

  &-title {
    font-size: 12px;
    color: $black-88;
    font-family: 'WorkSans-SemiBold';
  }

  &-multiple {
    margin-top: 24px;

    .scenario-group-card {
      width: 33.3%;
      padding: 40px 30px;

      >.anticon {
        font-size: 16px;
      }
    }
  }

  &-card {
    border-radius: 20px;
    border: 1px dashed $black-10;
    background: $black-2;
    padding: 16px 30px;
    text-align: center;
    margin-top: 5px;
    position: relative;

    &-title {
      line-height: 22px;
    }

    &-action {
      color: $blue-navy;
      text-decoration: underline;
      line-height: 22px;
    }

    &-description {
      position: absolute;
      right: 12px;
      top: 12px;
    }

    .info-icon {
      font-size: 24px;
    }

    .anticon {
      font-size: 24px;
      color: $black-45;
      margin-bottom: 8px;
    }

    .ant-typography {
      font-family: 'WorkSans';
    }
  }
}