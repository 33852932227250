@import '../../../assets/styles/variables.scss';


.blade.ant-drawer {
	.ant-drawer-content {
		padding: 0;

		.ant-drawer-body {
			padding: 20px 24px;

			.steps-content {
				.parameters-wizard {
					.loader {
						display: flex;
						justify-content: center;
						margin-top: 16px;
					}
				}

				.ant-collapse {
					.ant-collapse-header {
						padding: 24px;
					}

					.ant-collapse-item-active {
						.ant-collapse-header {
							padding: 24px 24px 0;
						}
					}

					&.specify-time-filter {
						.ant-collapse-header {
							height: 32px;
							padding: 0;
							text-align: center;
							align-items: center;
						}

						.ant-collapse-item-active {
							.ant-collapse-header {
								padding: 0 24px;
							}
						}
					}
				}

				.parameters-wizard .ant-collapse .ant-collapse-content .ant-collapse-content-box .blade-buttons {
					span {
						display: inline;
					}

					.ant-btn {
						&:not(.selected):hover {
							border-color: rgba(0, 0, 0, 0.16);
							box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.02);
						}
					}

					.ant-space-item .link-button .ant-btn {
						border: 0;
					}
				}
			}


		}
	}

	.buyer_type-info {
		font-size: 16px;
	}
}


.exclusive-from-comparison svg {
	transform: rotate(180deg);
}

.scenario-switcher-summary {
	font-size: 14px;
	margin-right: 16px;

	.anticon {
		font-size: 12px;
		margin-left: 4px;
	}
}

.blade-buttons {
	button {
		&.selected {
			color: $blue-navy;
			border-color: $blue-navy;

			&:hover {
				color: $blue-navy;
				border-color: $blue-navy;
			}

			&.category {
				color: white;
			}
		}
	}
}

.audience-size {
	text-align: right;

	.ant-spin {
		margin-bottom: 4px;
		margin-top: 3px;
		display: inline-block;
	}

	&-error {
		font-size: 12px;
		font-family: 'WorkSans';
		color: $orange-sunset;

		.ant-btn {
			font-size: 12px;
			padding: 0;
			height: unset;
		}
	}

	.title-caption {
		font-family: 'WorkSans-SemiBold';
		margin-top: -3px;
	}
}