@import '../../assets/styles/variables.scss';

.error-retry-circle {
  padding: 24px 0;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 16px;

  .anticon {
    font-size: 48px;
    color: $orange-sunset;
  }

  .error-title {
    font-size: 16px;
    font-family: 'WorkSans-SemiBold';
  }

  .text {
    color: $black-45;
  }
}