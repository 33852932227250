@import '../../../assets/styles/variables.scss';

.summary-card-list {
  position: relative;

  &-action-wrapper {
    position: absolute;
    height: 100%;
    left: 0;
    width: 260px;
    background: linear-gradient(90deg, #FFF 0%, rgba(255, 255, 255, 0.00) 69.3%);
    z-index: 2;
    pointer-events: none;

    &.right {
      left: calc(100% - 260px);
      background: linear-gradient(-90deg, #FFF 0%, rgba(255, 255, 255, 0.00) 69.3%);
    }
  }

  &-action {
    position: absolute;
    left: 24px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 3;
    pointer-events: all;

    &.right {
      left: calc(100% - 64px);
    }
  }
}

.summary-cards-wrapper {
  overflow: hidden;
  position: relative;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
}

.attribute-summary {
  padding: 0 24px 24px;
  transition: 0.6s;

  &.count-3 {
    .attribute-summary-item {
      width: calc((100% - 32px)/3);
    }
  }

  &.slider {
    width: calc((100% - 100px) / 3 * var(--items-count));

    .attribute-summary-item {
      width: 0;
      flex-basis: 50%;
    }
  }

  &-item {
    width: 50%;
    border-radius: 8px;
    border: 1px solid $black-10;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    padding-bottom: 60px;
  }

  &-section {
    padding: 12px 24px;
    border-bottom: 1px solid $black-10;
    position: relative;

    &.bottom {
      border-top: 1px solid $black-10;
      border-bottom: 0;
      position: absolute;
      bottom: 0;
      width: 100%;

      .ant-btn {
        width: 100%;
      }
    }

    &.center {
      padding: 16px;
      border: 0;
    }

    .short-description {
      margin: 0 0 16px;
      font-size: 12px;
      line-height: 20px;
    }

    .title {
      font-size: 16px;
      font-family: 'WorkSans-SemiBold';
      line-height: 24px;
    }

    .name-count {
      display: flex;
      width: calc(100% - 110px);

      b {
        color: initial;
        padding-left: 5px;
      }
    }

    .name {
      color: $black-45;
    }

    .no-popover {
      &:after {
        content: ':'
      }
    }
  }
}

.names-popover {
  max-width: 500px;

  span {
    color: $black-45;
  }
}