.summary-wizard {
	margin-bottom: 8px;

	.tooltip-tags {
		display: flex;
		flex-wrap: wrap;
	}
}

.summary-tags {
	margin-left: -2px;
}