@import '../../../../assets/styles/variables.scss';

.bar-chart {
  &-subtitle {
    line-height: 39px;
    font-size: 16px;
  }

  &-subtitle-info {
    p {
      line-height: 1.2;
      margin-bottom: 10px;
    }
  }
}

.bar-chart-diagram {
  display: flex;

  &.with-index {
    padding-top: 30px;
  }

  .index-label {
    position: absolute;
    width: 60px;
    text-align: right;
    top: 10px;
    font-size: 12px;
    color: $black-65;
  }

  .diagram-labels {
    display: flex;
    list-style: none;
    padding: 0;
    width: 92px;
    justify-content: space-between;
    flex-direction: column;
    gap: 14px;
    margin: 8px 0 13px;
    flex: 0 0 92px;

    .label {
      display: flex;
      align-items: center;
      height: var(--label-height);

      span {
        line-height: 1.5;
        width: 92px;
        padding-right: 12px;
        font-size: 12px;
        color: $black-65;
      }
    }
  }

  .bar-diagram {
    max-width: calc(100% - 92px);
    display: flex;
    flex: 1 1;
  }
}