@import '../../../assets/styles/variables.scss';


.default-attributes {
  display: flex;
  flex-wrap: wrap;
  gap: 4px 10px;

  &-info {
    display: flex;
    width: calc(50% - 5px);
    gap: 12px;
    align-items: flex-start;

    .icon {
      width: 36px;
      height: 36px;
      border-radius: 36px;
      background-color: $blue-zircon;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      color: $blue-navy;
      flex: 0 0 36px;
    }

    .info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      font-size: 12px;
      line-height: 20px;
      color: $black-45;
      max-width: calc(100% - 48px);

      b {
        font-size: 14px;
        line-height: 22px;
        color: $black-88;
      }
    }
  }
}