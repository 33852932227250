@import '../../../../../assets/styles/variables.scss';

.flavors-sizes {
  display: inline-block;
  margin-right: 16px;
  margin-bottom: 16px;

  &-wrapper {
    width: 100%;
  }

  &.message,
  &.visible {
    width: 100%;
    display: block;
  }

  &.visible {
    &:first-child {
      border-bottom: 1px solid $black-10;
    }
  }

  .loading {
    margin-top: 5px;
  }

  &-title {
    margin-top: 16px;
  }

  &-message {
    margin-top: 10px;
  }

  .flavors-sizes-list {
    margin: 16px 0;

    .ant-tree-switcher {
      width: 20px;
    }

    .ant-tree {
      &-treenode {
        position: relative;
        width: 100%;
        padding: 6px;
        margin-bottom: 4px;

        &-motion {
          width: 100%;
        }

        .ant-tree-checkbox {
          margin-inline-end: 4px;
        }

        &.parent {
          background-color: $blue-zircon;
          border-radius: 4px;
          width: 100%;
          padding: 9px 12px;
          position: relative;
          font-family: 'WorkSans-SemiBold';
          margin-bottom: 4px;
        }

        &.highlight {
          background-color: $black-4;
        }
      }

      &-node {
        &-content-wrapper {
          &:hover {
            background-color: transparent;
          }
        }
      }
    }

    .ant-tree-switcher {
      position: absolute;
      right: 12px;
      top: 50%;
      transform: translateY(-50%);
      width: 16px;
      height: 16px;

      &:hover {
        &:before {
          background-color: transparent;
        }
      }

      svg {
        font-size: 16px;
        color: $black-45;
      }

      &_close {
        svg {
          transform: rotate(-180deg);
        }
      }
    }
  }
}

.flavors-sizes-title {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 16px;
}