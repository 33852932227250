@mixin truncate($max-width) {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: $max-width;
}

@mixin flex-row-center {
	display: flex;
	justify-content: center;
}

@mixin flex-column {
	display: flex;
	flex-direction: column;
}

@mixin flex-column-center {
	display: flex;
	flex-direction: column;
	align-items: center;
}