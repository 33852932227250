@import '../../../../assets/styles/variables.scss';

.submit-for-activation {
  position: absolute;
  right: 24px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;

  .ant-dropdown {
    .ant-dropdown-menu-item {
      height: 40px;
      padding: 0 24px;

      .anticon {
        font-size: 16px;
      }
    }
  }
}

.activation-button {
  padding: 0;
  color: $black-88;
  text-decoration: underline;
  gap: 8px;

  .anticon {
    font-size: 16px;
  }
}

.specify-attributes {
  border-radius: 6px;
  border: 1px solid $black-16;
  background: $white;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
  margin-top: 24px;

  &-title {
    text-align: center;
    padding: 12px 0 8px;
  }

  .submit-filter {
    justify-content: space-between;
    gap: 8px;
    padding: 12px 24px;

    &.second-filter {
      border-top: 1px solid $black-10;
    }

    .add-new-filter {
      padding: 0;
      color: $black-88;
    }

    .ant-select-single {
      width: 140px;
    }

    .ant-tree-select {
      width: 100%;
    }

    .delete-filter {
      color: $black-45;
      font-size: 24px;
      padding: 0;

      &:hover {
        color: $red-crimson;
      }
    }
  }
}