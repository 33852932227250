@import '../../../assets/styles/variables.scss';

.index-description {
  .ant-popover-content {
    font-size: 12px;
    line-height: 20px;

    p {
      margin-top: 18px;

      &:first-child {
        margin-top: 0;
      }
    }
  }

  .green,
  .red {
    font-family: 'WorkSans-SemiBold';
  }

  .green {
    color: $green-japanese-laurel;
  }

  .red {
    color: $red-shiraz;
  }

  .ant-popover-arrow {
    left: auto !important;
    right: 3px;
  }
}