@import '../../../assets/styles/mixins.scss';

.ant-drawer-body {
	.steps-content {
		.parameters-wizard {
			.ant-collapse {
				user-select: none;
				margin-top: 1rem;

				.ant-collapse-item {
					.ant-collapse-content {
						border-top: none;

						.ant-collapse-content-box {
							padding: 4px 24px 24px;
						}

						.next-button {
							display: flex;
							justify-content: flex-end;
						}

						.ant-radio-group {
							.ant-radio-button-wrapper {
								text-align: center;
								width: calc(100% / 3);

								.prebuilt-audiences-button {
									@include flex-row-center;
									gap: 0.5rem;
								}
							}

							&.two-wide-button-group {
								.ant-radio-button-wrapper {
									width: calc(100% / 2);
								}
							}
						}
					}
				}

				&.specify-time-filter {
					.ant-collapse-item .ant-collapse-content {
						.ant-collapse-content-box {
							padding: 12px 16px;
							width: 100%;
							justify-content: center;
							display: flex;
						}
					}
				}
			}
		}
	}
}